import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { graphql, navigate } from 'gatsby'
import { KeyboardBackspace } from '@mui/icons-material'
import Carousel from 'react-material-ui-carousel'
import East from '@mui/icons-material/East'
import West from '@mui/icons-material/West'
import useMediaQueries from '../../hooks/useMediaQueries'
import dotGrid from '../../images/icons/dot_grid.svg'
import rLogo from '../../images/icons/small_linear_r.svg'
import serializers from '../../serializers/serializers'
import Layout from '../Layout/Layout'
import ContactForm from '../ContactForm/ContactForm'
import CarouselBlock, {
  sectionHeadingStyles,
} from '../CarouselBlock/CarouselBlock'
import SEO from '../SEO/SEO'
import ExtBlogArticleCard from '../ExtBlogArticleCard/ExtBlogArticleCard'
import { chunks } from '../BlogList/BlogList'

const ServicePage: React.FC<{
  // eslint-disable-next-line no-undef
  data: Queries.IndividualServicesPageQuery
}> = ({ data }) => {
  const { isSmall, theme } = useMediaQueries()

  return (
    <>
      <SEO
        title={data?.sanityService?.header || 'Services'}
        description={
          data?.sanityService?.description ||
          'A blog publishing helpful articles on frontend web development, Python, and other programming topics.'
        }
      />

      <Layout image={data?.sanityHomePage?.logo?.asset || undefined}>
        <img
          src={dotGrid}
          style={{
            height: isSmall ? '0px' : '520px',
            width: '520px',
            position: 'absolute',
            top: 175,
            left: '0px',
            zIndex: 0,
          }}
          alt=''
        />
        <Box
          maxWidth='1200px'
          position='relative'
          mx='auto'
          my='14vh'
          p={isSmall ? 2 : 5}
          borderRadius={isSmall ? 0 : '18px'}
          sx={{
            background: 'white',
          }}
        >
          <Button
            startIcon={
              <KeyboardBackspace sx={{ color: theme.palette.secondary.main }} />
            }
            sx={{
              mb: 4,
            }}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <article
            style={{
              paddingBottom: '64px',
              maxWidth: '680px',
              margin: 'auto',
            }}
            className='pt-3'
          >
            <BlockContent
              // eslint-disable-next-line no-underscore-dangle
              blocks={data?.sanityService?._rawOtherMedia}
              serializers={serializers}
            />
          </article>
          <img
            alt='logo'
            src={rLogo}
            style={{
              width: isSmall ? '100px' : '40vw',
              position: 'absolute',
              right: '0px',
              zIndex: 0,
              bottom: '0px',
              maxHeight: '50vh',
              opacity: 0.05,
            }}
          />
          {data.sanityService?.header === 'Tech Writing' && (
            <CarouselBlock
              titleWord1='Published'
              titleWord2='Elsewhere'
              text={
                <>
                  Below are articles that I have written for other blogs. The
                  majority are programming tutorials on how to use their
                  services with various frameworks or other technologies. Please
                  contact us at{' '}
                  <a
                    style={{
                      color: theme.palette.primary.light,
                      textDecorationColor: theme.palette.secondary.main,
                    }}
                    href='mailto:jarrett@retz.dev'
                  >
                    jarrett@retz.dev
                  </a>{' '}
                  for more information or questions on writing services.
                </>
              }
            >
              <Box>
                <Carousel
                  height={isSmall ? '2400px' : '862px'}
                  autoPlay={false}
                  indicators={false}
                  navButtonsAlwaysVisible
                  NextIcon={
                    <East
                      sx={{
                        '&:hover': {
                          color: theme.palette.secondary.main,
                        },
                      }}
                    />
                  }
                  PrevIcon={
                    <West
                      sx={{
                        '&:hover': {
                          color: theme.palette.secondary.main,
                        },
                      }}
                    />
                  }
                  navButtonsProps={{
                    className: 'carousel-nav-button',
                    style: {
                      background: theme.palette.primary.main,
                    },
                  }}
                >
                  {data?.allSanityArticle?.edges &&
                    [...chunks([...data.allSanityArticle.edges], 6)].map(
                      articleChunk => (
                        <Grid container pb={4} pt={8}>
                          {articleChunk &&
                            articleChunk.map(({ node }) => (
                              <Grid xs={12} sm={6} md={4} item key={node.id}>
                                <ExtBlogArticleCard
                                  key={node.id}
                                  title={node?.title}
                                  link={node.articleLink}
                                  imageData={
                                    node.mainImage?.asset?.gatsbyImageData
                                  }
                                  client={node?.client?.title}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      )
                    )}
                </Carousel>
              </Box>
            </CarouselBlock>
          )}
          <Box
            m='auto'
            maxWidth='1400px'
            py={15}
            px='15px'
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
          >
            <Typography
              color='primary'
              borderBottom={`1px solid ${theme.palette.secondary.dark}`}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...sectionHeadingStyles(isSmall)}
            >
              Get In Touch!
            </Typography>
            <ContactForm />
          </Box>
        </Box>
      </Layout>
    </>
  )
}

export const query = graphql`
  query IndividualServicesPage($header: String) {
    site {
      siteMetadata {
        title
      }
    }
    sanityHomePage {
      logo {
        asset {
          gatsbyImageData(width: 306, height: 100, placeholder: BLURRED)
        }
      }
    }
    sanityService(header: { eq: $header }) {
      _rawOtherMedia(resolveReferences: { maxDepth: 10 })
      inProgress
      description
      header
      subheader
      imageMedia {
        asset {
          gatsbyImageData(height: 300, placeholder: BLURRED)
        }
      }
      otherMedia {
        _rawChildren
      }
    }
    allSanityArticle(sort: { fields: _createdAt, order: DESC }) {
      edges {
        node {
          articleLink
          title
          mainImage {
            asset {
              gatsbyImageData(
                width: 400
                height: 200
                placeholder: BLURRED
                fit: FILLMAX
              )
            }
          }
          id
          client {
            title
          }
        }
      }
    }
  }
`

export default ServicePage
